.dnd {
  @media screen and (max-width: 1050px) {
    &__task-list {
      min-width: auto;
      width: 250px;
    }
  }

  @media screen and (max-width: 900px) {
    &__task-list {
      width: auto;
    }
  }

  @media screen and (max-width: 630px) {
    &__container {
      > div {
        display: block;
      }
    }
  }
}
