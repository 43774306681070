.page-project {
  &__tasks {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 10px;
  }

  &__line {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;
    column-gap: 20px;
  }
}
