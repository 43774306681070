.comments {
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  border: 1px solid #eee;
  border-width: 1px 0 0 0;

  &__title {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__icon {
    cursor: pointer;
  }

  &__item {
    padding: 10px 0;
  }

  &__item-child {
    padding-left: 50px;
  }

  &__textarea {
    width: 100%;
    max-width: 100%;
    max-height: 140px;
  }

  &__symbols {
    font-size: 12px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
