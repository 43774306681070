.time-in-work {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  margin: 20px 0 10px;

  &__picker {
    .MuiOutlinedInput-root {
      width: 100%;
    }
  }
}
