.modal-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #eee;
  background: #fff;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 16px 32px 24px 32px;
  max-height: 90vh;
  width: 85vw;
  overflow-y: auto;
  z-index: 1;
  animation: showModal 1s ease-in-out;

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.modal-form-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes showModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
