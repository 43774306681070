.edit-form {
  width: 100%;
  animation: showFields 1s ease-in-out;

  &__content {
    width: 100%;
  }

  &__line {
    display: grid;
    grid-template-columns: auto 35px 35px;
    align-items: center;
  }

  &__icon {
    justify-self: center;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

@keyframes showFields {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
