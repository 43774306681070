.dnd {
  margin: 10px 0;

  &__container {
    display: flex;

    > div {
      margin: 0;
      justify-content: space-between;
      min-height: auto;
    }
  }

  &__task-list {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    background: #f3f3f3;
    min-width: 300px;
    padding: 15px 15px;
  }

  &__task-column {
    margin: 8px;
    display: flex;
    width: 100%;
    min-height: 80vh;
  }

  &__title {
    padding: 2px 10px;
    align-self: flex-start;
  }

  &__task-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 15px;
    min-height: 106px;
    border-radius: 5px;
    max-width: 311px;
    background: white;
    margin-top: 15px;
    user-select: none;
  }
}
