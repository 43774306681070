.file-upload {
  display: flex;
  border: 1px solid #eee;
  border-width: 1px 0 0 0;
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;

  &__line {
    display: flex;
    justify-content: space-between;
  }

  &__delete-files {
    cursor: pointer;
    padding-left: 25px;
  }

  &__attach {
    position: relative;
    display: block;
    opacity: 0.6;
    cursor: pointer;
    margin-top: 10px;
  }

  &__input {
    position: absolute;
    opacity: 0;
  }
}
