.task-short {
  padding: 0 15px 20px;

  &__text {
    font-size: 12px;
  }

  h2,
  p {
    margin: 5px 0;
    word-break: break-all;

    @media screen and (max-width: 770px) {
      word-break: break-word;
    }
  }
}
