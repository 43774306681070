.app {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.app-container {
  width: 1160px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 20px);
  justify-content: space-between;
  padding: 0 20px 20px 20px;
}

.app-content {
  min-height: calc(100% - 112px);
  box-sizing: border-box;
  padding: 20px 0;
}
