.task {
  @media screen and (max-width: 900px) {
    &__info {
      grid-template-columns: 50% 50%;
    }
  }

  @media screen and (max-width: 640px) {
    &__info {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin: 20px 0;
    }
  }
}
