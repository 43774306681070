.task {
  padding: 0 15px 20px;
  position: relative;

  &__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 20px;
    width: 100%;
    margin: 20px 0;
  }

  &__done {
    opacity: 0.5;
    pointer-events: none;
  }

  &__closed-task {
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: all;
  }

  h2,
  p {
    margin: 5px 0;
    word-break: break-all;
  }
}
