.content-form-task {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;

  &__line {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    column-gap: 10px;
  }

  &__time {
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .MuiOutlinedInput-root,
  .MuiInput-root {
    width: 50%;
  }

  &__datepicker {
    width: 50%;

    .MuiOutlinedInput-root {
      width: 100%;
    }
  }

  &__work-time {
    width: 30px;
  }
}
